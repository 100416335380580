import React, { Fragment } from "react";

export const ITALIAN = {
  LANG_CODE: "it",
  COUNTRY_CODE: "it",
  CURRENCY: {
    symbol: "€",
    name: "EUR",
    id: "0",
    minPaymentAmount: "0.01",
    maxPaymentAmount: "15000",
    billingLabel: "EUR",
    traderKey: "Vuoto",
  },
  FOOTER: {
    copyright: "Eligma s.r.l.",
    version: "versione",
  },
  LOGIN: {
    username: "Nome utente",
    password: "Password",
    usernameEmptyError: "Inserire nome utente",
    passwordEmptyError: "Inserire password",
    login: "ACCEDI",
    notAuthorized: "Non autorizzato",
    hidePassword: "Nascondi",
    showPassword: "Visualizza",
    invalidCredentials: "Nome utente o password non corretti",
  },
  HEADER: {
    help: "GUIDA",
    callAgent: "support@gocrypto.com",
    agentNumber: "",
  },
  SIDEBAR: {
    logout: "Esci",
    newPayment: "Nuovo pagamento",
    archive: "Archivio",
    billing: "Fatturazione",
    faq: "Domande frequenti",
  },
  ARCHIVE: {
    overviewTitle: "Elenco delle transazioni ricevute",
    refreshLabel: "AGGIORNA",
    exportLabel: "ESPORTA",
    tableHeaders: {
      ID: "ID",
      lastUpdatedTime: "ORA",
      lastUpdatedDate: "DATA",
      lastUpdated: (
        <span>
          {" "}
          ORA <span className="mobile-hidden"> DATA </span>{" "}
        </span>
      ),
      amount: "VALORE",
      combinedAmount: (
        <span>
          VALORE <span className="mobile-hidden"> </span>{" "}
        </span>
      ),
      cryptoCurrency: "VALUTA",
      cryptoAmount: "VALORE IN CRIPTO",
      print: "STAMPA",
      status: "STATO",
      referenceNumber: "RIFERIMENTO",
      revert: "Annulla",
    },
    export: {
      startDate: "Data d'inizio",
      endDate: "Data di fine",
      error: "Selezionare intervallo di date",
      exportTitle: "Esportate i pagamenti",
      lastMonth: "Mese scorso",
      showResults: "VISUALIZZA RISULTATI",
      successfulPayments: "Pagamenti riusciti",
    },
    printLabel: "Stampa",
    print: {
      amountLabel: "Importo in valuta",
      transactionInfoLabel: "Informazioni sulla transazione",
      dateTimeLabel: "Data e ora",
    },
    emptyList: "Nell'archivio non ci sono pagamenti",
  },
  PAYMENT: {
    stepLabel: "passo",
    fromLabel: "di",
    createPayment: "Crea pagamento",
    paymentReady: "Pronto per il pagamento",
    paymentFinished: "Termina pagamento",
    amount: "Importo d'acquisto",
    amountErrorLow: "L'importo inserito è troppo basso",
    amountErrorHigh: "L'importo inserito è troppo alto",
    referenceNumberLabel: "Numero di riferimento",
    createPaymentButton: "Continua per pagare",
    cancelPaymentButton: "cancella pagamento",
    qrCodeReady: "Codice QR pronto per la scansione",
    resetPaymentButton: "Nuovo pagamento",
    paymentStatus: {
      CRYPTO_PAYMENT_STATUS_OPENED: "APERTO",
      CRYPTO_PAYMENT_STATUS_IN_PAYMENT: "IN CORSO",
      CRYPTO_PAYMENT_STATUS_PAID: "RIUSCITO",
      CRYPTO_PAYMENT_STATUS_PROCESSING: "IN ELABORAZIONE",
      CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "SCADUTO",
      CRYPTO_PAYMENT_STATUS_FAILED: "NON RIUSCITO",
      CRYPTO_PAYMENT_STATUS_NOT_VALID: "INVALIDO",
      CRYPTO_PAYMENT_STATUS_REVERTED: "RIMBORSATO",
      CRYPTO_PAYMENT_STATUS_CANCELED: "CANCELLATO",
      CRYPTO_PAYMENT_STATUS_UNDERPAID: "TROPPO BASSO",
    },
    paymentStatusLabel: {
      CRYPTO_PAYMENT_STATUS_OPENED: "Codice QR pronto per la scansione",
      CRYPTO_PAYMENT_STATUS_PAID: "Pagamento riuscito",
      CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "Il tempo per il pagamento è scaduto",
      CRYPTO_PAYMENT_STATUS_UNDERPAID:
        "Pagamento troppo basso.\nSe avete bisogno di aiuto, scriveteci all’indirizzo: support@gocrypto.com",
    },
    currencyNotice: "Vuoto",
  },
  EXPORT: {
    exportTransactionsLabel: "ESPORTA LE TRANSAZIONI",
    timeRangeLabel: "Periodo di tempo",
    fileTypeLabel: "Tipo di file",
    exportLabel: "ESPORTA",
    lastMonth: "Vuoto",
    showResults: "Vuoto",
  },
  REPORT: {
    dailyReport: {
      title: "Fatturazione",
      exportLabel: "ESPORTA",
      print: "STAMPA",
      noTransactions: "Nessuna transazione",
      currencyLabel: "Valore in",
      totalLabel: "Totale in",
      back: "INDIETRO",
      amountLabel: "IMPORTO",
      currencyInLabel: "in",
    },
  },
  ERROR: {
    unknownCause: "Si è verificato un errore imprevisto",
    reload: "Si prega di provare a ricaricare/svuotare la memoria transitoria",
    report: "Se il problema persiste, si prega di segnalarcelo",
  },
  GOCRYPTO: "Vuoto",
  TOOLTIPS: {
    PAYMENT: {
      mobile: {
        0: "Utilizzare la tastiera per inserire l'importo d'acquisto.",
        1: 'Premere il tasto "Continua per pagare". Di conseguenza si aprirà la schermata per la scansione del codice QR.',
      },
      tablet: {
        0: "Utilizzare la tastiera per inserire l'importo d'acquisto.",
        1: 'Premere il tasto "Continua per pagare". Di conseguenza si aprirà la schermata per la scansione del codice QR.',
      },
      desktop: {
        0: "Utilizzare la tastiera per inserire l'importo d'acquisto.",
        1: 'Premere il tasto "Continua per pagare". Di conseguenza si aprirà la schermata per la scansione del codice QR.',
      },
    },
    ARCHIVE: {
      mobile: {
        0: "Visualizzate i pagamenti in base alla data e al loro stato.",
        1: "Esportate i pagamenti in base all'ora e al loro risultato in formato PDF o CSV.",
      },
      tablet: {
        0: "Visualizzate i pagamenti in base alla data e al loro stato.",
        1: "Esportate i pagamenti in base all'ora e al loro risultato in formato PDF o CSV.",
      },
      desktop: {
        0: "Visualizzate i pagamenti in base alla data e al loro stato.",
        1: "Esportate i pagamenti in base all'ora e al loro risultato in formato PDF o CSV.",
      },
    },
    next: "AVANTI",
  },
  FAQ: {
    title: "Web POS - domande frequenti",
    QA: [
      {
        question: "Come posso creare un pagamento con GoCrypto?",
        answer: (
          <Fragment>
            <p>
              Nel menu principale selezionate "Nuovo pagamento" e con l'uso
              della tastiera o del touch screen, inserite l'importo d'acquisto
              nel campo di inserimento dedicato ad esso. Se desiderate, potete
              aggiungere anche un numero di riferimento.
            </p>
            <p>
              Cliccate su "Crea pagamento", dopo il quale sarà creato un codice
              QR. Chiedete all'acquirente di farne una scansione, se lo schermo
              non è facilmente accessibile, il cliente può fare una scansione
              dell'adesivo che avete collocato davanti alla vostra cassa.
            </p>
            <p>
              Una volta completato il processo, una freccia verde apparirà con
              lo stato "Pagamento riuscito". Questo indica che i fondi sono
              stati ricevuti e potete emettere la fattura.{" "}
            </p>
            <p>
              A fini contabili, cliccando su "Archivio", potete stampare la
              ricevuta di conferma.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question: 'Pagamento scaduto". Cosa significa?',
        answer: (
          <Fragment>
            <p>
              Quando viene creato un pagamento, sulla schermata appare un codice
              QR insieme ad un cronometro con il conto alla rovescia. Da questo
              momento, l'utente ha 5 minuti di tempo per completare il
              pagamento. Se il pagamento non viene effettuato in questo tempo,
              il processo scade ed è necessario creare il pagamento ancora una
              volta.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Ho inserito un importo di pagamento sbagliato e il codice QR è già stato creato. E adesso?",
        answer: (
          <Fragment>
            <p>
              {" "}
              Nessun problema! Semplicemente cliccate sul tasto "Cancella
              pagamento" e ripetete il processo.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Dove posso rivedere la cronologia dei pagamenti ed esportarli?",
        answer: (
          <Fragment>
            <p>
              Nel menu principale cliccate su "Archivio"; qui potete vedere la
              cronologia completa dei pagamenti. È possibile filtrare i
              risultati visualizzati in base allo stato del pagamento e alla
              data. A fini contabili, l'Archivio può essere esportato in formato
              CSV o PDF.
            </p>
          </Fragment>
        ),
      },
      {
        question: "Posso generare l'estratto conto giornaliero?",
        answer: (
          <Fragment>
            <p>
              Certo, basta cliccare su Fatturazione nel menu principale; da qui
              è possibile stampare l'estratto conto giornaliero, in cui i
              pagamenti effettuati con singole valute vengono uniti.
            </p>
          </Fragment>
        ),
      },
      {
        question: "Quali criptovalute sono supportate da GoCrypto?",
        answer: (
          <Fragment>
            <p>
              Crypto enables payments with 40 cryptocurrencies. Bitcoin, Bitcoin
              Cash, Ether, the GoC token, Litecoin, Tezos and the Viberate token
              are just some of them. However, you do not need any knowledge
              about them. Once the user scans the QR code, they select the
              payment currency in their crypto wallet application, and the
              process for you is the same in all cases.{" "}
            </p>
            <p>
              In addition to cryptocurrencies, GoCrypto also supports payments
              with pre-deposited Euro value in some countries.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Quali portafogli possono utilizzare i miei acquirenti per effettuare pagamenti?",
        answer: (
          <Fragment>
            <p>
              Buyers can use the Elly Wallet, Binance Pay (these two support
              multiple currencies) and the Bitcoin.com Wallet (supports Bitcoin
              Cash). The process is completely the same for you in all cases.
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "È possibile effettuare pagamenti anche con valute regolari (quindi non criptovalute)?",
        answer: (
          <Fragment>
            <p>
              Sì, oltre alle criptovalute, in alcune regioni GoCrypto supporta
              anche pagamenti con importi in valore di euro precedentemente
              caricati.
            </p>
          </Fragment>
        ),
      },
      {
        question: "Il mio acquirente chiede un rimborso.",
        answer: (
          <Fragment>
            <p>
              Una volta completato con successo il pagamento, i fondi non
              possono più essere rimborsati attraverso il sistema GoCrypto; il
              rimborso deve essere effettuato dal commerciante stesso.
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Ho problemi con la creazione di un pagamento o con l'utilizzo del POS GoCrypto.",
        answer: (
          <Fragment>
            <p>
              Potete inviare un'e-mail all'indirizzo{" "}
              <a href="mailto:support@gocrypto.com">support@gocrypto.com</a>.{" "}
            </p>
          </Fragment>
        ),
      },
    ],
  },
};
