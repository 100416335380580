import React, { Fragment } from "react";

export const RUSSIAN = {
  LANG_CODE: "ru",
  COUNTRY_CODE: "ru",
  CURRENCY: {
    symbol: "€",
    name: "EUR",
    id: "0",
    minPaymentAmount: "0.01",
    maxPaymentAmount: "15000",
    billingLabel: "EUR",
    traderKey: "",
  },
  FOOTER: {
    copyright: "Eligma Ltd.",
    version: "версия",
  },
  LOGIN: {
    username: "Имя пользователя",
    password: "Пароль",
    usernameEmptyError: "Введите имя пользователя",
    passwordEmptyError: "Введите пароль",
    login: "ВХОД В СИСТЕМУ",
    notAuthorized: "Не авторизован",
    hidePassword: "Спрятать",
    showPassword: "Показать",
    invalidCredentials: "Неправильное имя пользователя или пароль",
  },
  HEADER: {
    help: "ПОМОЩЬ",
    callAgent: "support@gocrypto.com",
    agentNumber: "",
  },
  SIDEBAR: {
    logout: "Выйти",
    newPayment: "Новый платёж",
    archive: "Архив",
    billing: "Биллинг",
    faq: "Вопросы-Ответы",
  },
  ARCHIVE: {
    overviewTitle: "Список полученных транзакций",
    refreshLabel: "ОБНОВИТЬ",
    exportLabel: "ЭКСПОРТ",
    tableHeaders: {
      ID: "ID",
      lastUpdatedTime: "ВРЕМЯ",
      lastUpdatedDate: "ДАТА",
      lastUpdated: (
        <span>
          ВРЕМЯ <span className="mobile-hidden"> ДАТА</span>
        </span>
      ),
      amount: "ЦЕНА",
      combinedAmount: (
        <span>
          ЦЕНА <span className="mobile-hidden"> </span>
        </span>
      ),
      cryptoCurrency: "ВАЛЮТА",
      cryptoAmount: "СТОИМОСТЬ В КРИПТОВАЛЮТЕ",
      print: "РАСПЕЧАТАТЬ",
      status: "СТАТУС",
      referenceNumber: "ССЫЛКА",
      revert: "аннулировать",
    },
    export: {
      startDate: "Начало",
      endDate: "Конец",
      error: "Выбрать период времени",
      exportTitle: "Экспортировать платежи",
      lastMonth: "Месяц",
      showResults: "ПОКАЗАТЬ РЕЗУЛЬТАТЫ",
      successfulPayments: "Успешные",
    },
    printLabel: "Распечатать",
    print: {
      amountLabel: "Сумма в валюте",
      transactionInfoLabel: "Информация о транзакции",
      dateTimeLabel: "Дата и время",
    },
    emptyList: "В архиве нет платежей",
  },
  PAYMENT: {
    stepLabel: "шаг",
    fromLabel: "из",
    createPayment: "Создать платеж",
    paymentReady: "Готов к оплате",
    paymentFinished: "Завершить платеж",
    amount: "Сумма платежа",
    amountErrorLow: "Введенная сумма слишком мала",
    amountErrorHigh: "Введенная сумма слишком высока",
    referenceNumberLabel: "Справочный номер",
    createPaymentButton: "Продолжить оплату",
    cancelPaymentButton: "отменить платеж",
    qrCodeReady: "QR-код готов к сканированию",
    resetPaymentButton: "Новый платёж",
    paymentStatus: {
      CRYPTO_PAYMENT_STATUS_OPENED: "ОТКРЫТО",
      CRYPTO_PAYMENT_STATUS_IN_PAYMENT: "В платёж",
      CRYPTO_PAYMENT_STATUS_PAID: "УСПЕШНО",
      CRYPTO_PAYMENT_STATUS_PROCESSING: "ОБРАБОТКА",
      CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "ИСТЕКШИЙ",
      CRYPTO_PAYMENT_STATUS_FAILED: "НЕУСПЕШНО",
      CRYPTO_PAYMENT_STATUS_NOT_VALID: "НЕДЕЙСТВИТЕЛЬНО",
      CRYPTO_PAYMENT_STATUS_REVERTED: "Возврат",
      CRYPTO_PAYMENT_STATUS_CANCELED: "ОТМЕНЕНО",
      CRYPTO_PAYMENT_STATUS_UNDERPAID: "НЕДОСТАТОЧНО",
    },
    paymentStatusLabel: {
      CRYPTO_PAYMENT_STATUS_OPENED: "QR-код готов к сканированию",
      CRYPTO_PAYMENT_STATUS_PAID: "Платеж успешно завершен",
      CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "Время для совершения платежа истекло",
      CRYPTO_PAYMENT_STATUS_UNDERPAID:
        "Платеж слишком маленький.\nДля получения помощи, пожалуйста, свяжитесь с GoCrypto по адресу: support@gocrypto.com",
    },
    currencyNotice: "",
  },
  EXPORT: {
    exportTransactionsLabel: "ЭКСПОРТИРОВАТЬ ТРАНЗАКЦИИ",
    timeRangeLabel: "Период времени",
    fileTypeLabel: "Тип файла",
    exportLabel: "ЭКСПОРТИРОВАТЬ",
    lastMonth: "",
    showResults: "",
  },
  REPORT: {
    dailyReport: {
      title: "Биллинг",
      exportLabel: "ЭКСПОРТИРОВАТЬ",
      print: "РАСПЕЧАТАТЬ",
      noTransactions: "Нет транзакций",
      currencyLabel: "Стоимость в",
      totalLabel: "Всего в",
      back: "НАЗАД",
      amountLabel: "СУММА",
      currencyInLabel: "в",
    },
  },
  ERROR: {
    unknownCause: "Произошла неожиданная ошибка",
    reload: "Пожалуйста, сначала попробуйте перезагрузить/очистить кэш.",
    report: "Если проблема не устранена, пожалуйста, сообщите об этом",
  },
  GOCRYPTO: "",
  TOOLTIPS: {
    PAYMENT: {
      mobile: {
        0: "С помощью клавиатуры введите сумму платежа.",
        1: 'Нажмите "Перейти к продолжению платежа". При этом открывается экран для сканирования QR-кода.',
      },
      tablet: {
        0: "С помощью клавиатуры введите сумму платежа.",
        1: 'Нажмите "Перейти к продолжению платежа". При этом открывается экран для сканирования QR-кода.',
      },
      desktop: {
        0: "С помощью клавиатуры введите сумму платежа.",
        1: 'Нажмите "Перейти к продолжению платежа". При этом открывается экран для сканирования QR-кода.',
      },
    },
    ARCHIVE: {
      mobile: {
        0: "Просмотр платежей по их времени и статусу.",
        1: "Экспорт платежей с сортировкой по времени и статусу завершенности в формате PDF или CSV.",
      },
      tablet: {
        0: "Просмотр платежей по их времени и статусу.",
        1: "Экспорт платежей с сортировкой по времени и статусу завершенности в формате PDF или CSV.",
      },
      desktop: {
        0: "Просмотр платежей по их времени и статусу.",
        1: "Экспорт платежей с сортировкой по времени и статусу завершенности в формате PDF или CSV.",
      },
    },
    next: "СЛЕДУЮЩИЙ",
  },
  FAQ: {
    title: "webPOS - часто задаваемые вопросы",
    QA: [
      {
        question: "Как создать платеж GoCrypto?",
        answer: (
          <Fragment>
            <p>
              Выберите "Новый платеж" в главном меню и с помощью клавиатуры или
              сенсорного экрана введите сумму платежа в специальном поле ввода.
              Опционально может быть добавлен номер ссылки.
            </p>
            <p>
              Нажмите кнопку "Создать платеж", при этом будет сгенерирован
              QR-код. Попросите покупателя отсканировать этот QR-код или
              наклейку, размещенную перед кассовой стойкой, если доступ к экрану
              продавца для покупателя затруднен.
            </p>
            <p>
              Как только покупатель завершит процесс, появится зеленая стрелка
              со статусом "Оплата произведена успешно". Это означает, что
              средства были получены, и вы можете выставить счет-фактуру.{" "}
            </p>
            <p>
              Для своих бухгалтерских нужд вы можете перейти в раздел "Архив" и
              распечатать квитанцию с подтверждением.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question: "Срок платежа истек. Что это означает?",
        answer: (
          <Fragment>
            <p>
              При создании платежа появился QR-код с таймером обратного отсчета.
              С этого момента пользователь должен завершить платеж за 5 минут.
              Если потребовалось больше времени, то процесс истек, и платеж
              придется создать еще раз.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Я ввел неправильную сумму платежа, а QR-код уже сгенерирован. Что делать?",
        answer: (
          <Fragment>
            <p>
              Не переживайте, просто нажмите кнопку "Отмена оплаты" и повторите
              процесс еще раз.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question: "Где можно просмотреть историю платежей и экспортировать её?",
        answer: (
          <Fragment>
            <p>
              Выберите раздел "Архив" в главном меню, чтобы получить доступ к
              полной истории платежей. Список можно отфильтровать по статусу и
              дате оплаты. Для ваших бухгалтерских целей архивы можно
              экспортировать в формате CSV или PDF.
            </p>
          </Fragment>
        ),
      },
      {
        question: "Можно ли создать ежедневную выписку по счетам?",
        answer: (
          <Fragment>
            <p>
              Разумеется, в главном меню выберите раздел Биллинг, где вы можете
              распечатать ежедневную выписку, в которой объединены платежи,
              произведенные в различных валютах.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question: "Какие криптовалюты поддерживаются?",
        answer: (
          <Fragment>
            <p>
              Crypto enables payments with 40 cryptocurrencies. Bitcoin, Bitcoin
              Cash, Ether, the GoC token, Litecoin, Tezos and the Viberate token
              are just some of them. However, you do not need any knowledge
              about them. Once the user scans the QR code, they select the
              payment currency in their crypto wallet application, and the
              process for you is the same in all cases.{" "}
            </p>
            <p>
              In addition to cryptocurrencies, GoCrypto also supports payments
              with pre-deposited Euro value in some countries.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question: "Какие кошельки покупатели могут использовать для оплаты?",
        answer: (
          <Fragment>
            <p>
              Buyers can use the Elly Wallet, Binance Pay (these two support
              multiple currencies) and the Bitcoin.com Wallet (supports Bitcoin
              Cash). The process is completely the same for you in all cases.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question: "Поддерживается ли оплата в обычной валюте?",
        answer: (
          <Fragment>
            <p>
              Да, в дополнение к криптовалютам, в некоторых регионах GoCrypto
              поддерживает и платежи с предварительно депонированной стоимостью
              евро.
            </p>
          </Fragment>
        ),
      },
      {
        question: "Мой покупатель запрашивает возврат средств.",
        answer: (
          <Fragment>
            <p>
              После успешного совершения платежа, средства не могут быть
              возвращены через систему GoCrypto, возврат должен быть осуществлен
              продавцом.
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "У меня возникли проблемы с созданием платежа или использованием GoCrypto POS.",
        answer: (
          <Fragment>
            <p>
              Вы можете отправить письмо на{" "}
              <a href="mailto:support@gocrypto.com">support@gocrypto.com</a>.{" "}
            </p>
          </Fragment>
        ),
      },
    ],
  },
};
