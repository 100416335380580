import React, { Fragment } from "react";

export const SLOVENIAN = {
  LANG_CODE: "sl",
  COUNTRY_CODE: "si",
  CURRENCY: {
    symbol: "€",
    name: "EUR",
    id: "",
    minPaymentAmount: "0.01",
    maxPaymentAmount: "",
    billingLabel: "EUR",
    traderKey: "",
  },
  FOOTER: {
    copyright: "Eligma d.o.o.",
    version: "različica",
  },
  LOGIN: {
    username: "Uporabniško ime",
    password: "Geslo",
    usernameEmptyError: "Vnesite uporabniško ime",
    passwordEmptyError: "Vnesite geslo",
    login: "PRIJAVA",
    notAuthorized: "Ni dovoljeno",
    hidePassword: "Skrij",
    showPassword: "Prikaži",
    invalidCredentials: "Napačno uporabniško ime ali geslo",
  },
  HEADER: {
    help: "POMOČ",
    callAgent: "support@gocrypto.com",
    agentNumber: "",
  },
  SIDEBAR: {
    logout: "Odjava",
    newPayment: "Novo plačilo",
    archive: (
      <span>
        Arhiv <span className="mobile-hidden">plačil</span>
      </span>
    ),
    billing: "Obračun",
    faq: "FAQ",
  },
  ARCHIVE: {
    overviewTitle: "Arhiv plačil",
    refreshLabel: "OSVEŽI",
    exportLabel: "IZVOZI",
    tableHeaders: {
      ID: "ID",
      lastUpdatedTime: "ČAS",
      lastUpdatedDate: "DATUM",
      lastUpdated: (
        <span>
          ČAS <span className="mobile-hidden">/ DATUM</span>
        </span>
      ),
      amount: "VREDNOST",
      combinedAmount: "VREDNOST",
      cryptoCurrency: "VREDNOST KRIPTO",
      cryptoAmount: "VREDNOST KRIPTO",
      print: "NATISNI",
      status: "STATUS",
      referenceNumber: "REFERENCA",
      revert: "Razveljavi",
    },
    export: {
      startDate: "Začetek",
      endDate: "Konec",
      error: "Izberite časovno obdobje",
      exportTitle: "Izvozite plačila",
      lastMonth: "Pretekli mesec",
      showResults: "PRIKAŽI REZULTATE",
      successfulPayments: "Uspešna plačila",
    },
    printLabel: "Print",
    print: {
      amountLabel: "Znesek v valuti",
      transactionInfoLabel: "Informacije o transakciji",
      dateTimeLabel: "Datum in čas",
    },
    emptyList: "V arhivu ni plačil",
  },
  PAYMENT: {
    stepLabel: "korak",
    fromLabel: "od",
    createPayment: "Ustvari plačilo",
    paymentReady: "Pripravljeno plačilo",
    paymentFinished: "Zaključek plačila",
    amount: "Znesek plačila",
    amountErrorLow: "Vnešen znesek je prenizek",
    amountErrorHigh: "Vnešen znesek je previsok",
    referenceNumberLabel: "Referenčna številka",
    createPaymentButton: "Nadaljuj na plačilo",
    cancelPaymentButton: "prekliči plačilo",
    qrCodeReady: "Koda pripravljena za skeniranje",
    resetPaymentButton: "Novo plačilo",
    paymentStatus: {
      CRYPTO_PAYMENT_STATUS_OPENED: "ODPRTO",
      CRYPTO_PAYMENT_STATUS_IN_PAYMENT: "V TEKU",
      CRYPTO_PAYMENT_STATUS_PAID: "USPEŠNO",
      CRYPTO_PAYMENT_STATUS_PROCESSING: "V OBDELAVI",
      CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "POTEKLO",
      CRYPTO_PAYMENT_STATUS_FAILED: "NEUSPEŠNO",
      CRYPTO_PAYMENT_STATUS_NOT_VALID: "NEVELJAVNO",
      CRYPTO_PAYMENT_STATUS_REVERTED: "Vrnjeno",
      CRYPTO_PAYMENT_STATUS_CANCELED: "PREKLICANO",
      CRYPTO_PAYMENT_STATUS_UNDERPAID: "PRENIZKO",
    },
    paymentStatusLabel: {
      CRYPTO_PAYMENT_STATUS_OPENED: "Koda pripravljena za skeniranje",
      CRYPTO_PAYMENT_STATUS_PAID: "Plačilo uspešno",
      CRYPTO_PAYMENT_STATUS_AUTO_CLOSED: "Čas za plačilo je potekel",
      CRYPTO_PAYMENT_STATUS_UNDERPAID:
        "Plačilo prenizko.\nZa pomoč se obrnite na GoCrypto: podpora@gocrypto.com ",
    },
    currencyNotice: "",
  },
  EXPORT: {
    exportTransactionsLabel: "Izvozi plačila",
    timeRangeLabel: "Časovni okvir",
    fileTypeLabel: "Tip datoteke",
    exportLabel: "IZVOZI",
    lastMonth: "",
    showResults: "",
  },
  REPORT: {
    dailyReport: {
      title: "Obračun",
      exportLabel: "IZVOZI",
      print: "NATISNI",
      noTransactions: "Obračun je prazen",
      currencyLabel: "valuta",
      totalLabel: "Skupaj v",
      back: "NAZAJ",
      amountLabel: "VSOTA",
      currencyInLabel: "v",
    },
  },
  ERROR: {
    unknownCause: "Prišlo je do nepričakovane napake",
    reload: "Izpraznite/osvežite predpomnilnik",
    report: "Če težava ne bo odpravljena, nas o njej obvestite",
  },
  GOCRYPTO: "0",
  TOOLTIPS: {
    PAYMENT: {
      mobile: {
        0: "Vnesite znesek nakupa z uporabo tipkovnice.",
        1: "Pritisnite gumb »Nadaljuj na plačilo«. Odprl se vam bo ekran za skeniranje QR-kode.",
      },
      tablet: {
        0: "Vnesite znesek nakupa z uporabo tipkovnice.",
        1: "Pritisnite gumb »Nadaljuj na plačilo«. Odprl se vam bo ekran za skeniranje QR-kode.",
      },
      desktop: {
        0: "Vnesite znesek nakupa z uporabo tipkovnice.",
        1: "Pritisnite gumb »Nadaljuj na plačilo«. Odprl se vam bo ekran za skeniranje QR-kode.",
      },
    },
    ARCHIVE: {
      mobile: {
        0: "Pregledujte plačila glede na čas in status.",
        1: "Izvozite plačila glede na čas in uspešnost v obliki PDF ali CSV.",
      },
      tablet: {
        0: "Pregledujte plačila glede na čas in status.",
        1: "Izvozite plačila glede na čas in uspešnost v obliki PDF ali CSV.",
      },
      desktop: {
        0: "Pregledujte plačila glede na čas in status.",
        1: "Izvozite plačila glede na čas in uspešnost v obliki PDF ali CSV.",
      },
    },
    next: "NAPREJ",
  },
  FAQ: {
    title: "Spletni POS – pogosta vprašanja",
    QA: [
      {
        question: "Kako ustvarim plačilo z GoCryptom?",
        answer: (
          <Fragment>
            <p>
              V glavnem meniju izberite »Novo plačilo« ter v ustrezno vnosno
              polje s pomočjo tipkovnice ali zaslona na dotik vpišite znesek za
              plačilo. Če želite, lahko dodate tudi referenčno številko plačila.
            </p>
            <p>
              Kliknite Nadaljuj na plačilo« in ustvarila se bo QR-koda. Kupca
              prosite, da poskenira to QR-kodo oziroma QR-kodo na nalepki pri
              blagajni (kadar kupci ne morejo dostopati do zaslona blagajne).
            </p>
            <p>
              Ko bo kupec s postopkom zaključil, se bo na zaslonu pojavila
              zelena puščica z napisom »Plačilo uspešno«. To pomeni, da so bila
              sredstva prejeta in da lahko izdate račun.
            </p>
            <p>
              Lahko kliknete tudi »Arhiv plačil« in si v računovodske namene
              natisnete potrdilo.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question: "Kaj pomeni obvestilo »Čas za plačilo je potekel?«",
        answer: (
          <Fragment>
            <p>
              Ko se ustvari plačilo, se na vašem zaslonu prikaže QR koda s
              časovnikom, kupec pa ima 5 minut časa, da zaključi s plačilom.
              Nato čas za plačilo poteče in treba je ustvariti novo plačilo.
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Vnesel sem napačen znesek za plačilo, QR-koda pa se je že ustvarila. Kaj zdaj?",
        answer: (
          <Fragment>
            <p>
              Brez skrbi! Pritisnite le na gumb »Prekliči plačilo« in ponovite
              postopek.
            </p>
          </Fragment>
        ),
      },
      {
        question: "Kje si lahko ogledam in izvozim zgodovino plačil?",
        answer: (
          <Fragment>
            <p>
              V glavnem meniju kliknite »Arhiv plačil«; tam lahko z drsnikom
              pregledujete celotno zgodovino plačil. Rezultate lahko tudi
              filtrirate po datumih in statusih plačila. V računovodske namene
              lahko Arhiv tudi izvozite v formatu CSV ali PDF.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question: "Ali lahko ustvarim dnevni obračun plačil?",
        answer: (
          <Fragment>
            <p>
              Seveda! V glavnem meniju kliknite »Obračun«; tam lahko natisnete
              dnevni obračun plačil v različnih valutah.
            </p>
          </Fragment>
        ),
      },
      {
        question: "Katere kriptovalute podpira GoCrypto?",
        answer: (
          <Fragment>
            <p>
              GoCrypto omogoča plačila z več kot 40 kriptovalutami; Bitcoin,
              Bitcoin Cash, Ether, GoC-žeton, Litecoin, Tezos in Viberate-žeton
              so le nekatere izmed njih, vendar znanja o njih ne potrebujete. Ko
              bo uporabnik poskeniral QR-kodo, bo v mobilni aplikaciji izbral
              valuto za plačilo, za vas pa je postopek vedno enak.{" "}
            </p>
            <p>
              Poleg plačil s kriptovalutami GoCrypto v nekaterih državah omogoča
              tudi plačilo z uporabo predhodno naložene evrske vrednosti.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question: "S katerimi denarnicami lahko plačujejo moji kupci?",
        answer: (
          <Fragment>
            <p>
              Vaši kupci lahko uporabijo denarnice Elly, Binance Pay (podpirata
              več kriptovalut) in Bitcoin.com Wallet (podpira valuto Bitcoin
              Cash). Ne glede na izbrano denarnico pa je postopek za vas vedno
              enak.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Ali je možno tudi plačevanje z običajnimi valutami (torej takšnimi, ki niso kriptovalute)?",
        answer: (
          <Fragment>
            <p>
              Poleg plačil s kriptovalutami GoCrypto v nekaterih državah omogoča
              tudi plačilo z uporabo predhodno naložene evrske vrednosti.{" "}
            </p>
          </Fragment>
        ),
      },
      {
        question: "Kupec zahteva vračilo kupnine.",
        answer: (
          <Fragment>
            <p>
              Po uspešno izvedenem plačilu vračilo kupnine prek sistema GoCrypto
              ni možno; vračilo mora opraviti trgovec.
            </p>
          </Fragment>
        ),
      },
      {
        question:
          "Pri ustvarjanju plačila ali uporabi POS-a GoCrypto imam težave.",
        answer: (
          <Fragment>
            <p>
              Prosimo, da kontaktirate našega agenta na številko +386 51 647
              071. Na voljo smo vam tudi na{" "}
              <a href="mailto:podpora@gocrypto.pos">podpora@gocrypto.com</a>.{" "}
            </p>
          </Fragment>
        ),
      },
    ],
  },
};
